import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // ← ポイント！
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models';
import { customerLabelName } from '../../../statics';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import PaginationCpn from '../../common/PaginationCpn';

// -------------
// Props
export type GaGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    keyword: string;
    openSearch: boolean;
    authRole:any;
};

// -------------
// Component
export const FaGrid = forwardRef((props: GaGridProps, ref) => {
    const { colState, pageSize, pageData, keyword, openSearch, authRole, onChangeSearchCondition,  } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();

    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    const cellNotifyDateTimeRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword)
        const {data} = props;
        const notify_datetime = data.notify_datetime;
        const subString = notify_datetime.substring(0,2) + "/" + notify_datetime.substring(2,4) + " " + notify_datetime.substring(4,6) + ":" + notify_datetime.substring(6,8);
        return (
            <>
                {subString}
            </>
        );
    };

    const renderMeasureMentValue1 = (props:any) => {
        const { ch1_kw } = props.data;
        if(ch1_kw && ch1_kw[0] === '.'){
            return '0' + ch1_kw;
        }
        return ch1_kw;
    }

    const renderMeasureMentValue2 = (props:any) => {
        const { ch2_kw } = props.data;
        if(ch2_kw && ch2_kw[0] === '.'){
            return '0' + ch2_kw;
        }
        return ch2_kw;
    }
    
    const renderMeasureMentValue3 = (props:any) => {
        const { ch3_kw } = props.data;
        if(ch3_kw && ch3_kw[0] === '.'){
            return '0' + ch3_kw;
        }
        return ch3_kw;
    }

    const renderMeasureMentValue4 = (props:any) => {
        const { ch4_kw } = props.data;
        if(ch4_kw && ch4_kw[0] === '.'){
            return '0' + ch4_kw;
        }
        return ch4_kw;
    }

    const renderMeasureMentValue5 = (props:any) => {
        const { ch5_kw } = props.data;
        if(ch5_kw && ch5_kw[0] === '.'){
            return '0' + ch5_kw;
        }
        return ch5_kw;
    }

    const renderMeasureMentValue6 = (props:any) => {
        const { ch6_kw } = props.data;
        if(ch6_kw && ch6_kw[0] == '.'){
            return '0' + ch6_kw;
        }
        return ch6_kw;
    }

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "No.",
            field: "id",
            rowDrag: false,
            width: 70,
        },
        {
            headerName: "通報日時",
            field: "notify_datetime",
            width: 140,
            rowDrag: false,
            cellRenderer: cellNotifyDateTimeRenderer,
        },
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            width: 200,
        },
        {
            headerName: "設置場所",
            field: "place",
            rowDrag: false,
            width: 200,
        },
        {
            headerName: "装置ID",
            field: "device_id",
            rowDrag: false,
            width: 120,
        },
        {
            headerName: "端末番号",
            field: "terminal_no",
            rowDrag: false,
            width: 120,
        },
        {
            headerName: "CH1-計測値",
            field: "ch1_kw",
            width: 120,
            rowDrag: false,
            valueGetter:renderMeasureMentValue1,
        },
        {
            headerName: "CH2-計測値",
            field: "ch2_kw",
            width: 120,
            rowDrag: false,
            valueGetter:renderMeasureMentValue2,
        },
        {
            headerName: "CH3-計測値",
            field: "ch3_kw",
            width: 120,
            rowDrag: false,
            valueGetter:renderMeasureMentValue3,
        },
        {
            headerName: "CH4-計測値",
            field: "ch4_kw",
            width: 120,
            rowDrag: false,
            valueGetter:renderMeasureMentValue4,
        },
        {
            headerName: "CH5-計測値",
            field: "ch5_kw",
            width: 120,
            rowDrag: false,
            valueGetter:renderMeasureMentValue5,
        },
        {
            headerName: "CH6-計測値",
            field: "ch6_kw",
            width: 120,
            rowDrag: false,
            valueGetter:renderMeasureMentValue6,
        },
    ];

    if(authRole && authRole.base_view_flag == 1){
        columnDefs.splice(2, 0, {
            headerName: "拠点",
            field: "base_name",
            width: 100,
        } as any);
    }

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword);

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 330px)' : 'calc(100vh - 240px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        cacheQuickFilter={true}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn pageData={pageData} onChangeSearchCondition={onChangeSearchCondition} limit={pageSize}
                    exportCsv={{ gridApi: gridRef?.current?.api, name: "測定値定期通報"}} enabledExportCsv={true} />
            </div>
        </>
    );
});

export default FaGrid;
