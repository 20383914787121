import { Button, DialogActions, Stack, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { padZeroToStart } from '../../utils';

export type SelectEarthFaultAlarmSettingDialogProps = {
    isOpen: boolean;
    onOK: (data: any) => void;
    onCancel: () => void;
    data: any;
    dialogTitle: string;
};

interface formProps{
    ct1_value:number;
    ct2_value:number;
}

export const SelectEarthFaultAlarmSettingDialog = (props: SelectEarthFaultAlarmSettingDialogProps) => {
    const { isOpen, onOK, onCancel, data, dialogTitle } = props;
    const [open, setOpen] = React.useState(isOpen);

    const { setValue, getValues, control, formState: { errors } } = useForm<formProps>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues: data,
    });

    const handleInputOnBlur = (e: any, name: any, maxLength: number) => {
        setValue(name, padZeroToStart(e.target.value, maxLength));
    }
    
    // Cancelボタンイベント
    const handleCancel = () => {
        setOpen(false);
        onCancel();
    };

    const handleUpdate = () => {
        if(!(errors?.ct2_value || errors?.ct1_value)){
            const params = {
                ...data,
                ct1_value: getValues("ct1_value"),
                ct2_value: getValues("ct2_value"),
            }
            setOpen(false);
            onOK(params);
        }        
    };

    return (
        <div>
            <Dialog open={open} maxWidth={"md"} fullWidth>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent sx={{ pb: 1.5 }}>
                <Stack spacing={2.5} style={{ marginLeft: '10px', width: '98%', position:"relative" }}>
                    <Stack paddingTop={"10px"} spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                        <Controller
                            control={control}
                            name="ct1_value"
                            rules={{ required: '必須項目です。入力してください。',
                                pattern: {
                                    value: /^[0-9\b]+$/,
                                    message: '数字を入力してください。'
                                }
                             }}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        fullWidth
                                        type="text"
                                        label={`CT1`}
                                        size="small"
                                        error={!!errors?.ct1_value}
                                        helperText={errors?.ct1_value?.message}
                                        inputProps={{
                                            maxLength:4
                                        }}
                                        onBlur={(e) => handleInputOnBlur(e, 'ct1_value', 4)}
                                    />
                                </>
                            )}
                        />
                    </Stack>
                    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} >
                        <Controller
                            control={control}
                            name="ct2_value"
                            rules={{ required: '必須項目です。入力してください。',
                                pattern: {
                                    value: /^[0-9\b]+$/,
                                    message: '数字を入力してください。'
                                }
                             }}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        {...field}
                                        fullWidth
                                        type="text"
                                        label={`CT2`}
                                        size="small"
                                        error={!!errors?.ct2_value}
                                        helperText={errors?.ct2_value?.message}
                                        inputProps={{
                                            maxLength:4
                                        }}
                                        onBlur={(e) => handleInputOnBlur(e, 'ct2_value', 4)}
                                    />
                                </>
                            )}
                        />
                    </Stack>
                </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                    <Button variant="contained" onClick={handleUpdate}>保存</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SelectEarthFaultAlarmSettingDialog;