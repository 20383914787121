import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Stack } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // ← ポイント！
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import PaginationCpn from '../../common/PaginationCpn';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import { convertTimeToSecond } from '../../../utils';
import { customerLabelName } from '../../../statics';
import TimelineIcon from '@mui/icons-material/Timeline';
// -------------
// Props
export type BeGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    keyword: string;
    openSearch: boolean;
    onShowDetails: (value: any, operation: string) => void;
    onChangeSearchCondition: (name: string, value: any) => void;
    onShowChart: (data:any) => void;
    authRole:any;
};

// -------------
// Component
export const BeGrid = forwardRef((props: BeGridProps, ref) => {
    const { colState, pageSize, pageData, keyword, openSearch, authRole, onChangeSearchCondition, onShowDetails, onShowChart } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();

    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRendererTime = (props: any) => {
        // 詳細ボタンイベント
        const handleEdit = () => {
            onShowDetails(props.data, "1");
        };

        if (props.data.restore_datetime != null) {
            return props.data.restore_datetime;
        }
        else if (props.data.restore_datetime == null && props.data.status_name !== "発生") {
            return;
        }

        return (
            <>
                <Stack direction="row">
                    <div>
                        {props.data.restore_datetime}
                    </div>
                    
                </Stack>
            </>
        );
    };

    const cellRendererLinkInformation = (props: any) => {
        return (
            <>
                <IconButton onClick={() => {
                    onShowChart(props.data);
                }} aria-label="edit" style={{ color: 'white', backgroundColor: '#1890ff', borderRadius: '3px',justifyContent:"center",margin:"3px"}} >
                    <TimelineIcon fontSize="small" />
                </IconButton>
            </>
        )
    }

    const cellMessageRendererComment = (props: any) => {
        // 詳細ボタンイベント
        const handleEdit = () => {
            onShowDetails(props.data, "2");
        };

        return (
            <>
                <Stack direction="row">
                    <div>
                        {props.data.comment}
                    </div>
                    <div style={{ marginInlineStart: 'auto' }}>
                        <IconButton
                            aria-label="edit"
                            onClick={handleEdit}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>
                </Stack>
            </>
        );
    };

    const cellStatusRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword)
        return (
            <>
                {props.data.status_name}
            </>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "No.",
            field: "id",
            rowDrag: false,
            width: 80,
        },
        {
            headerName: "状況",
            field: "status_name",
            rowDrag: false,
            cellRenderer: cellStatusRenderer,
            width: 80,
        },
        {
            headerName: "受信日時",
            field: "occurrence_time",
            rowDrag: false,
            width: 200,
        },
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            width: 150,
        },
        {
            headerName: "設置場所",
            field: "place",
            rowDrag: false,
            width: 150,
        },
        {
            headerName: "装置ID",
            field: "mainid",
            rowDrag: false,
            width: 110,
        },
        {
            headerName: "端末番号",
            field: "terminal_no",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "CH番号",
            field: "channel_no",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "接点名称",
            field: "point_name",
            rowDrag: false,
            width: 110,
        },
        {
            headerName: "波形",
            field: "custom",
            sortable:false,
            cellRenderer:cellRendererLinkInformation,
            width: 100,
        },
        {
            headerName: "電流値1",
            field: "kw_1",
            rowDrag: false,
            width: 100,
        },

        {
            headerName: "電流値2",
            field: "kw_2",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "電流値3",
            field: "kw_3",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "電流値4",
            field: "kw_4",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "電流値5",
            field: "kw_5",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "電流値6",
            field: "kw_6",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "電流値7",
            field: "kw_7",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "電流値8",
            field: "kw_8",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "電流値9",
            field: "kw_9",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "電流値10",
            field: "kw_10",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "電流値11",
            field: "kw_11",
            rowDrag: false,
            width: 100,
        },
        {
            headerName: "確認コメント",
            field: "comment",
            width: 550,
            cellRenderer: cellMessageRendererComment,
        },
    ];

    if(authRole && authRole.base_view_flag == 1){
        columnDefs.splice(3, 0, {
            headerName: "拠点",
            field: "base_name",
            width: 100,
        } as any);
    }

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)
    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 335px)' : 'calc(100vh - 240px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        cacheQuickFilter={true}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn
                    pageData={pageData}
                    onChangeSearchCondition={onChangeSearchCondition}
                    limit={pageSize}
                    exportCsv={{ gridApi: gridRef?.current?.api, name: "微地絡トレンド電流" }}
                    enabledExportCsv={true}
                />
            </div>
        </>
    );
});

export default BeGrid;
