import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import SelectEarthFaultAlarmSettingDialog from '../../dialogs/SelectEarthFaultAlarmSettingDialog';
import FetchHelper from '../../FetchHelper';
import { disabledRefreshAllBtnStyle, disabledRefreshSuccessIconStyles, enableRefreshAllBtnStyle, enableRefreshSuccessIconStyles, pollingColStyle, pollingColStyleTitle } from '../../styles';

const fetchId = "EARTH_FAULT_ALARM_SETTING_FETCH";
const UPDATE_EARTH_FAULT_ALARM_SETTING = "UPDATE_EARTH_FAULT_ALARM_SETTING";

const params = {
    mainid: "",
    isRefreshAllBegin: 0,
    isEdit: false,
    setIsEdit: (isEdit: boolean) => { },
    setEarthFaultAlarmSetting: (isEdit: boolean) => { },
    isDisbleUpdateBtn: true,
    isDisbleRefeshBtn: true,
    measurementInfo: [] as any,
    setRefreshGroup: (disabled: boolean, name: string) => { },
    isRefreshGroup: false,
    setDisabledRefreshGroupBtn: (disabled: boolean, name: string) => { },
}

export type EarthFaultAlarmSettingProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & typeof params;

export const EarthFaultAlarmSetting = (props: EarthFaultAlarmSettingProps) => {
    const { mainid, isRefreshAllBegin, isEdit, isDisbleUpdateBtn, measurementInfo, fetchState,
        onSearch, setIsEdit, setEarthFaultAlarmSetting, onUpdate, onUpdateSuccess, onUpdateError, isDisbleRefeshBtn, setRefreshGroup, isRefreshGroup, setDisabledRefreshGroupBtn } = props;
    const [openSelectDialog, setOpenSelectDialog] = React.useState(false);
    const [isFetchAll, setIsFetchAll] = React.useState(false);
    const [showDoneIcon,setShowDoneIcon] = React.useState(false);
    const [showData,setShowData] = React.useState<any>();
    const [tempData,setTempData] = React.useState<any>(Array(20).fill(null));
    const [isRefreshSucesses, setIsRefreshSuccesses] = React.useState(Array(20).fill(false));
    const [fetchLoadingDevices, setFetchLoadingDevices] = React.useState(Array(20).fill(false));
    const [editFlg, setEditFlg] = React.useState(Array(20).fill(false));
    const [detailData,setDetailData] = React.useState<any>();
    const [titleEdit,setTitleEdit] = React.useState<any>();
    const deviceNos = ['00', '01', '02', '03', '04'];
    const codeGets = ["QR","QS","QT","QU"];
    const codeUpdates = ["QV", "QW", "QX", "QY"];

    React.useEffect(() => {
        if(measurementInfo){
            const temp_data_QR = measurementInfo.microEarthFaultLowerLimitDetection;
            let result = {};
            if(temp_data_QR){
                const temp_data_QR_0 =  temp_data_QR.find((item:any) => item.device_no == deviceNos[0]);
                const temp_data_QR_1 =  temp_data_QR.find((item:any) => item.device_no == deviceNos[1]);
                const temp_data_QR_2 =  temp_data_QR.find((item:any) => item.device_no == deviceNos[2]);
                const temp_data_QR_3 =  temp_data_QR.find((item:any) => item.device_no == deviceNos[3]);
                const temp_data_QR_4 =  temp_data_QR.find((item:any) => item.device_no == deviceNos[4]);
                result = {
                    ...result,
                    data_QR_0:temp_data_QR_0,
                    data_QR_1:temp_data_QR_1,
                    data_QR_2:temp_data_QR_2,
                    data_QR_3:temp_data_QR_3,
                    data_QR_4:temp_data_QR_4,
                }
            }
            const temp_data_QS = measurementInfo.minorGroundFaultWarningDetection;
            if(temp_data_QS){
                const temp_data_QS_0 =  temp_data_QS.find((item:any) => item.device_no == deviceNos[0]);
                const temp_data_QS_1 =  temp_data_QS.find((item:any) => item.device_no == deviceNos[1]);
                const temp_data_QS_2 =  temp_data_QS.find((item:any) => item.device_no == deviceNos[2]);
                const temp_data_QS_3 =  temp_data_QS.find((item:any) => item.device_no == deviceNos[3]);
                const temp_data_QS_4 =  temp_data_QS.find((item:any) => item.device_no == deviceNos[4]);
                result = {
                    ...result,
                    data_QS_0:temp_data_QS_0,
                    data_QS_1:temp_data_QS_1,
                    data_QS_2:temp_data_QS_2,
                    data_QS_3:temp_data_QS_3,
                    data_QS_4:temp_data_QS_4,
                }
            }
            const temp_data_QT = measurementInfo.minorGroundFaultAlarm;
            if(temp_data_QT){
                const temp_data_QT_0 =  temp_data_QT.find((item:any) => item.device_no == deviceNos[0]);
                const temp_data_QT_1 =  temp_data_QT.find((item:any) => item.device_no == deviceNos[1]);
                const temp_data_QT_2 =  temp_data_QT.find((item:any) => item.device_no == deviceNos[2]);
                const temp_data_QT_3 =  temp_data_QT.find((item:any) => item.device_no == deviceNos[3]);
                const temp_data_QT_4 =  temp_data_QT.find((item:any) => item.device_no == deviceNos[4]);
                result = {
                    ...result,
                    data_QT_0:temp_data_QT_0,
                    data_QT_1:temp_data_QT_1,
                    data_QT_2:temp_data_QT_2,
                    data_QT_3:temp_data_QT_3,
                    data_QT_4:temp_data_QT_4,
                }
            }
            const temp_data_QU = measurementInfo.earthFaultAlarm;
            if(temp_data_QU){
                const temp_data_QU_0 =  temp_data_QU.find((item:any) => item.device_no == deviceNos[0]);
                const temp_data_QU_1 =  temp_data_QU.find((item:any) => item.device_no == deviceNos[1]);
                const temp_data_QU_2 =  temp_data_QU.find((item:any) => item.device_no == deviceNos[2]);
                const temp_data_QU_3 =  temp_data_QU.find((item:any) => item.device_no == deviceNos[3]);
                const temp_data_QU_4 =  temp_data_QU.find((item:any) => item.device_no == deviceNos[4]);
                result = {
                    ...result,
                    data_QU_0:temp_data_QU_0,
                    data_QU_1:temp_data_QU_1,
                    data_QU_2:temp_data_QU_2,
                    data_QU_3:temp_data_QU_3,
                    data_QU_4:temp_data_QU_4,
                }
            }
            setShowData(result);
        }
    },[measurementInfo]);

    const refreshAll = () => {
        if (isRefreshGroup === true) {
            const temp_fetch = Array(20).fill(true);
            const temp_refresh = Array(20).fill(false);
            setFetchLoadingDevices(temp_fetch);
            setIsRefreshSuccesses(temp_refresh);
            setIsFetchAll(true);
            handleRefresh(deviceNos[0],codeGets[0]);
        }
    }

    // eslint-disable-next-line
    React.useEffect(refreshAll, [isRefreshAllBegin, isRefreshGroup]);

    const handleDisabledRefreshAll = () => {
        if (fetchLoadingDevices.every((item) => !item) && editFlg.every((item) => !item)) {
            setDisabledRefreshGroupBtn(false, fetchId);
        } else {
            setDisabledRefreshGroupBtn(true, fetchId);
        }
    }
    // eslint-disable-next-line
    React.useEffect(handleDisabledRefreshAll, [fetchLoadingDevices,editFlg]);

    React.useEffect(() => {
        const data = editFlg.find((item:any) => item == true);
        if(!data){
            setIsEdit(false);
        }
        // eslint-disable-next-line
    }, [editFlg]);

    const getIndexEdit = (device_no:any, code_update:any) => {
        let indexEditFlag = Number(device_no);
    
        if (code_update == codeUpdates[0]) {
        } else if (code_update == codeUpdates[1]) {
            indexEditFlag += 5;
        } else if (code_update == codeUpdates[2]) {
            indexEditFlag += 10;
        } else if (code_update == codeUpdates[3]) {
            indexEditFlag += 15;
        }
    
        return indexEditFlag;
    }

    const getIndexGet = (device_no: string, code_get: string) => {
        let deviceIndex: number = Number(device_no);
    
        if (code_get === codeGets[0]) {
            // No change to deviceIndex
        } else if (code_get === codeGets[1]) {
            deviceIndex += 5;
        } else if (code_get === codeGets[2]) {
            deviceIndex += 10;
        } else if (code_get === codeGets[3]) {
            deviceIndex += 15;
        }
    
        return deviceIndex;
    }

    const handleEdit = (device_no:any,code_update:any,code_get:any) => {
        setIsEdit(true);
        let indexEditFlag = getIndexEdit(Number(device_no),code_update);
        setEditFlg(prev => {
            const newState = [...prev];
            newState[indexEditFlag] = true;
            return newState;
        });
        handleRefresh(device_no,code_get);
    };

    const handleUpdateOk = (request: any) => {
        setOpenSelectDialog(false);
        let indexEditFlag = getIndexEdit(Number(request.device_no),request.code_update);
        const params = {
            mainid: mainid,
            device_no: request.device_no,
            ct1_value: request.ct1_value,
            ct2_value: request.ct2_value,
        }
        setEditFlg(prev => {
            const newState = [...prev];
            newState[indexEditFlag] = true;
            return newState;
        });
        onUpdate(params,request.code_update);
        setTempData(
            (prev:any) => {
                const newState = [...prev];
                newState[indexEditFlag] = {...params,code_update: request.code_update,code_get: request.code_get,indexEditFlag};
                return newState;
            });
    };

    const handleUpdateCancel = () => {
        setOpenSelectDialog(false);
    };

    const handleUpdateSuccess = (data:any, device_no:any,code_update:any) => {
        let indexEditFlag = getIndexEdit(Number(device_no),code_update);
        setEditFlg(prev => {
            const newState = [...prev];
            newState[indexEditFlag] = false;
            return newState;
        });
        if(data && data.result_code == 0){
            onUpdateSuccess(titleEdit);            
            const temp_show_data = {...showData};
            const key = `data_${tempData[indexEditFlag].code_get}_${Number(device_no)}`;
            temp_show_data[key].ct1_value = tempData[indexEditFlag].ct1_value;
            temp_show_data[key].ct2_value = tempData[indexEditFlag].ct2_value;
            setShowData(temp_show_data);
        }else{
            onUpdateError(titleEdit);
            setShowDoneIcon(false);
        }
    }

    const handleUpdateError = (data:any, device_no:any,code_update:any) => {
        let indexEditFlag = getIndexEdit(Number(device_no),code_update);
        onUpdateError(titleEdit);
        setEditFlg(prev => {
            const newState = [...prev];
            newState[indexEditFlag] = false;
            return newState;
        });
        setIsEdit(false);
        setShowDoneIcon(false);
    }

    const handleRefresh = (device_no:any,code_get:any) => {
        let deviceIndex = getIndexGet(device_no,code_get);
        setFetchLoadingDevices(prev => {
            const newState = [...prev];
            newState[deviceIndex] = true;
            return newState;
        });
        setIsRefreshSuccesses(prev => {
            const newState = [...prev];
            newState[deviceIndex] = false;
            return newState;
        });
        const params = {
            device_no:device_no,
            mainid:mainid,
            code_get:code_get,
        }
        onSearch(params, fetchId + `_device_no=${device_no}_code_get=${code_get}`);
    };

    const handleSuccess = (data:any, device_no:any,code_get:any) => {
        let device_no_temp = Number(device_no);
        let code_update = 'QV';
        let title = '微地絡下限検出 設定 / ';
        if(code_get == codeGets[1]){
            device_no_temp += 5;
            code_update = codeUpdates[1];
            title = '微地絡警戒検出 設定 / ';
        }else if(code_get == codeGets[2]){
            device_no_temp += 10;
            code_update = codeUpdates[2];
            title = "微地絡警報 設定 / ";
        }else if(code_get == codeGets[3]){
            device_no_temp += 15;
            code_update = codeUpdates[3];
            title = "地絡警報 設定 / ";
        }

        if(device_no == deviceNos[0]){
            title +=  "本体";
        }else if(device_no == deviceNos[1]){
            title +=  "子機1";
        }else if(device_no == deviceNos[2]){
            title +=  "子機2";
        }else if(device_no == deviceNos[3]){
            title +=  "子機3";
        }else if(device_no == deviceNos[4]){
            title +=  "子機4";
        }

        if(editFlg[device_no_temp]){
            setEditFlg(prev => {
                const newState = [...prev];
                newState[device_no_temp] = false;
                return newState;
            });
            const temp_index = device_no_temp;
            setIsRefreshSuccesses(prev => {
                const newState = [...prev];
                newState[temp_index] = true; 
                return newState;
            });
            setFetchLoadingDevices(prev => {
                const newState = [...prev];
                newState[device_no_temp] = false; 
                return newState;
            });
            

            if(data){
                let temp_data = {...showData};
                temp_data = { ...temp_data, [`data_${code_get}_${Number(device_no)}`]: data };
                setShowData(temp_data);
                setOpenSelectDialog(true);
                setTitleEdit(title);
                const params = {
                    device_no,
                    code_get,
                    title,
                    code_update,
                    ct1_value: data?.ct1_value,
                    ct2_value: data?.ct2_value,
                };
                setDetailData(params);
            }
        }else{
            setFetchLoadingDevices(prev => {
                const newState = [...prev];
                newState[device_no_temp] = false; 
                return newState;
            });
            if(data){
                let temp_data = {...showData};
                temp_data = { ...temp_data, [`data_${code_get}_${Number(device_no)}`]: data };
                
                if (isRefreshGroup) {
                    const temp_index = device_no_temp;
                    setIsRefreshSuccesses(prev => {
                        const newState = [...prev];
                        newState[temp_index] = true; 
                        return newState;
                    });
                
                    const deviceChanges:any = {
                        "QR": { change: 0, next: codeGets[1], reset: 4 },
                        "QS": { change: -5, next: codeGets[2], reset: 4 },
                        "QT": { change: -10, next: codeGets[3], reset: 4 },
                        "QU": { change: -15, next: null, reset: null }
                    };
                
                    const currentDevice = deviceChanges[code_get];
                
                    if (currentDevice) {
                        device_no_temp += currentDevice.change;
                        if(code_get == codeGets[3] && device_no_temp == 4){
                            setRefreshGroup(false, fetchId);
                            setIsFetchAll(false);
                        }else{
                            if (device_no_temp === currentDevice.reset) {
                                handleRefresh(deviceNos[0], currentDevice.next);
                            } else {
                                handleRefresh('0' + (device_no_temp + 1), code_get);
                            }
                        }
                    }
                } else {
                    setIsRefreshSuccesses(prev => {
                        const newState = [...prev];
                        newState[device_no_temp] = true; 
                        return newState;
                    });
                }
                setShowData(temp_data);
            }
        }
        
    }

    const handleError = (data:any, device_no:any,code_get:any) => {
        let device_no_temp = getIndexGet(device_no,code_get);

        setFetchLoadingDevices(prev => {
            const newState = [...prev];
            newState[device_no_temp] = false; 
            return newState;
        });

        if (isRefreshGroup) {
            const temp_index = device_no_temp;
            setIsRefreshSuccesses(prev => {
                const newState = [...prev];
                newState[temp_index] = false; 
                return newState;
            });
        
            const deviceChanges:any = {
                "QR": { change: 0, next: codeGets[1], reset: 4 },
                "QS": { change: -5, next: codeGets[2], reset: 4 },
                "QT": { change: -10, next: codeGets[3], reset: 4 },
                "QU": { change: -15, next: null, reset: null }
            };
        
            const currentDevice = deviceChanges[code_get];
        
            if (currentDevice) {
                device_no_temp += currentDevice.change;
                if(code_get == codeGets[3] && device_no_temp == 4){
                    setRefreshGroup(false, fetchId);
                    setIsFetchAll(false);
                }else{
                    if (device_no_temp === currentDevice.reset) {
                        handleRefresh(deviceNos[0], currentDevice.next);
                    } else {
                        handleRefresh('0' + (device_no_temp + 1), code_get);
                    }
                }
            }
        } else {
            setIsRefreshSuccesses(prev => {
                const newState = [...prev];
                newState[device_no_temp] = true; 
                return newState;
            });
        }
    }

    return (
        <>
            {/* device 00 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[0] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[0],codeUpdates[0],codeGets[0])} disabled={isDisbleUpdateBtn || isEdit || editFlg[0] || fetchLoadingDevices[0] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[0] === true || fetchLoadingDevices[0] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[0],codeGets[0])} disabled={isDisbleRefeshBtn || editFlg[0] || fetchLoadingDevices[0] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[0] === true || fetchLoadingDevices[0] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡下限検出 設定 / 本体'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[0] || editFlg[0] ? (
                        <>
                            <div style={{ display: 'flex', position: 'relative' }}>
                                <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                    <tbody>
                                        <tr>
                                            <td style={pollingColStyleTitle}>CT1</td>
                                        </tr>
                                        <tr>
                                            <td style={pollingColStyleTitle}>CT2</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                    <CircularProgress style={{ margin: 'auto' }} />
                                </div>
                            </div>
                        </>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_0?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_0?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QR_0?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 01 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[1] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[1],codeUpdates[0],codeGets[0])} disabled={isDisbleUpdateBtn || isEdit || editFlg[1] || fetchLoadingDevices[1] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[1] === true || fetchLoadingDevices[1] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[1],codeGets[0])} disabled={isDisbleRefeshBtn || editFlg[1] || fetchLoadingDevices[1] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[1] === true || fetchLoadingDevices[1] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡下限検出 設定 / 子機1'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[1] || editFlg[1] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_1?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_1?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QR_1?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 02 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[2] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[2],codeUpdates[0],codeGets[0])} disabled={isDisbleUpdateBtn || isEdit || editFlg[2] || fetchLoadingDevices[2] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[2] === true || fetchLoadingDevices[2] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[2],codeGets[0])} disabled={isDisbleRefeshBtn || editFlg[2] || fetchLoadingDevices[2] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[2] === true || fetchLoadingDevices[2] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡下限検出 設定 / 子機2'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[2] || editFlg[2] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_2?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_2?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QR_2?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 03 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[3] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[3],codeUpdates[0],codeGets[0])} disabled={isDisbleUpdateBtn || isEdit || editFlg[3] || fetchLoadingDevices[3] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[3] === true || fetchLoadingDevices[3] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[3],codeGets[0])} disabled={isDisbleRefeshBtn || editFlg[3] || fetchLoadingDevices[3] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[3] === true || fetchLoadingDevices[3] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡下限検出 設定 / 子機3'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[3] || editFlg[3] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_3?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_3?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QR_3?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 04 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[4] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[4],codeUpdates[0],codeGets[0])} disabled={isDisbleUpdateBtn || isEdit || editFlg[4] || fetchLoadingDevices[4] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[4] === true || fetchLoadingDevices[4] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[4],codeGets[0])} disabled={isDisbleRefeshBtn || editFlg[4] || fetchLoadingDevices[4] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[4] === true || fetchLoadingDevices[4] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡下限検出 設定 / 子機4'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[4] || editFlg[4] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_4?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QR_4?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QR_4?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* QS  QW  */}
            {/* device 00 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[5] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[0],codeUpdates[1],codeGets[1])} disabled={isDisbleUpdateBtn || isEdit || editFlg[5] || fetchLoadingDevices[5] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[5] === true || fetchLoadingDevices[5] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[0],codeGets[1])} disabled={isDisbleRefeshBtn || editFlg[5] || fetchLoadingDevices[5] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[5] === true || fetchLoadingDevices[5] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警戒検出 設定 / 本体'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[5] || editFlg[5] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_0?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_0?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QS_0?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 01 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[6] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[1],codeUpdates[1],codeGets[1])} disabled={isDisbleUpdateBtn || isEdit || editFlg[6] || fetchLoadingDevices[6] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[6] === true || fetchLoadingDevices[6] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[1],codeGets[1])} disabled={isDisbleRefeshBtn || editFlg[6] || fetchLoadingDevices[6] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[6] === true || fetchLoadingDevices[6] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警戒検出 設定 / 子機1'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[6] || editFlg[6] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_1?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_1?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QS_1?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 02 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[7] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[2],codeUpdates[1],codeGets[1])} disabled={isDisbleUpdateBtn || isEdit || editFlg[7] || fetchLoadingDevices[7] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[7] === true || fetchLoadingDevices[7] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[2],codeGets[1])} disabled={isDisbleRefeshBtn || editFlg[2] || fetchLoadingDevices[7] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[7] === true || fetchLoadingDevices[7] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警戒検出 設定 / 子機2'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[7] || editFlg[7] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_2?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_2?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QS_2?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 03 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[8] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[3],codeUpdates[1],codeGets[1])} disabled={isDisbleUpdateBtn || isEdit || editFlg[8] || fetchLoadingDevices[8] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[8] === true || fetchLoadingDevices[8] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[3],codeGets[1])} disabled={isDisbleRefeshBtn || editFlg[3] || fetchLoadingDevices[8] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[8] === true || fetchLoadingDevices[8] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警戒検出 設定 / 子機3'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[8] || editFlg[8] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_3?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_3?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QS_3?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 04 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[9] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[4],codeUpdates[1],codeGets[1])} disabled={isDisbleUpdateBtn || isEdit || editFlg[9] || fetchLoadingDevices[9] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[9] === true || fetchLoadingDevices[9] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[4],codeGets[1])} disabled={isDisbleRefeshBtn || editFlg[4] || fetchLoadingDevices[9] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[9] === true || fetchLoadingDevices[9] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警戒検出 設定 / 子機4'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[9] || editFlg[9] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_4?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QS_4?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QS_4?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* QT  QX  */}
            {/* device 00 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[10] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[0],codeUpdates[2],codeGets[2])} disabled={isDisbleUpdateBtn || isEdit || editFlg[10] || fetchLoadingDevices[10] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[10] === true || fetchLoadingDevices[10] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[0],codeGets[2])} disabled={isDisbleRefeshBtn || editFlg[10] || fetchLoadingDevices[10] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[10] === true || fetchLoadingDevices[10] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警報 設定 / 本体'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[10] || editFlg[10] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_0?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_0?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QT_0?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 01 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[11] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[1],codeUpdates[2],codeGets[2])} disabled={isDisbleUpdateBtn || isEdit || editFlg[11] || fetchLoadingDevices[11] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[11] === true || fetchLoadingDevices[11] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[1],codeGets[2])} disabled={isDisbleRefeshBtn || editFlg[11] || fetchLoadingDevices[11] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[11] === true || fetchLoadingDevices[11] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警報 設定 / 子機1'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[11] || editFlg[11] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_1?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_1?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QT_1?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 02 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[12] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[2],codeUpdates[2],codeGets[2])} disabled={isDisbleUpdateBtn || isEdit || editFlg[12] || fetchLoadingDevices[12] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[12] === true || fetchLoadingDevices[12] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[2],codeGets[2])} disabled={isDisbleRefeshBtn || editFlg[12] || fetchLoadingDevices[12] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[12] === true || fetchLoadingDevices[12] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警報 設定 / 子機2'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[12] || editFlg[12] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_2?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_2?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QT_2?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 03 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[13] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[3],codeUpdates[2],codeGets[2])} disabled={isDisbleUpdateBtn || isEdit || editFlg[13] || fetchLoadingDevices[13] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[13] === true || fetchLoadingDevices[13] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[3],codeGets[2])} disabled={isDisbleRefeshBtn || editFlg[13] || fetchLoadingDevices[13] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[13] === true || fetchLoadingDevices[13] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警報 設定 / 子機3'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[13] || editFlg[13] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_3?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_3?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QT_3?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 04 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[14] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[4],codeUpdates[2],codeGets[2])} disabled={isDisbleUpdateBtn || isEdit || editFlg[14] || fetchLoadingDevices[14] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[14] === true || fetchLoadingDevices[14] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[4],codeGets[2])} disabled={isDisbleRefeshBtn || editFlg[14] || fetchLoadingDevices[14] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[14] === true || fetchLoadingDevices[14] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='微地絡警報 設定 / 子機4'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[14] || editFlg[14] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_4?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QT_4?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QT_4?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* QU  QY  */}
            {/* device 00 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[15] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[0],codeUpdates[3],codeGets[3])} disabled={isDisbleUpdateBtn || isEdit || editFlg[15] || fetchLoadingDevices[15] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[15] === true || fetchLoadingDevices[15] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[0],codeGets[3])} disabled={isDisbleRefeshBtn || editFlg[15] || fetchLoadingDevices[15] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[15] === true || fetchLoadingDevices[15] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='地絡警報 設定 / 本体'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[15] || editFlg[15] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_0?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_0?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QU_0?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 01 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[16] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[1],codeUpdates[3],codeGets[3])} disabled={isDisbleUpdateBtn || isEdit || editFlg[16] || fetchLoadingDevices[16] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[16] === true || fetchLoadingDevices[16] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[1],codeGets[3])} disabled={isDisbleRefeshBtn || editFlg[16] || fetchLoadingDevices[16] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[16] === true || fetchLoadingDevices[16] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='地絡警報 設定 / 子機1'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[16] || editFlg[16] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_1?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_1?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QU_1?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 02 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[17] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[2],codeUpdates[3],codeGets[3])} disabled={isDisbleUpdateBtn || isEdit || editFlg[17] || fetchLoadingDevices[17] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[17] === true || fetchLoadingDevices[17] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[2],codeGets[3])} disabled={isDisbleRefeshBtn || editFlg[17] || fetchLoadingDevices[17] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[17] === true || fetchLoadingDevices[17] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='地絡警報 設定 / 子機2'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[17] || editFlg[17] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_2?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_2?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QU_2?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 03 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[18] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[3],codeUpdates[3],codeGets[3])} disabled={isDisbleUpdateBtn || isEdit || editFlg[18] || fetchLoadingDevices[18] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[18] === true || fetchLoadingDevices[18] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[3],codeGets[3])} disabled={isDisbleRefeshBtn || editFlg[18] || fetchLoadingDevices[18] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[18] === true || fetchLoadingDevices[18] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='地絡警報 設定 / 子機3'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[18] || editFlg[18] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_3?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_3?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QU_3?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {/* device 04 */}
            <Card style={{ margin: '0 auto 12px', backgroundColor: '#f5faff', width: '100%' }}>
                <CardHeader
                    avatar={
                        <PublishedWithChangesIcon style={isRefreshSucesses[19] ? enableRefreshSuccessIconStyles : disabledRefreshSuccessIconStyles} />
                    }
                    action={
                        <div>
                            <IconButton aria-label="edit" onClick={() => handleEdit(deviceNos[4],codeUpdates[3],codeGets[3])} disabled={isDisbleUpdateBtn || isEdit || editFlg[19] || fetchLoadingDevices[19] || isFetchAll}>
                                <EditIcon fontSize="medium" style={isDisbleUpdateBtn || isEdit || editFlg[19] === true || fetchLoadingDevices[19] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                            <IconButton aria-label="refresh" onClick={() => handleRefresh(deviceNos[4],codeGets[3])} disabled={isDisbleRefeshBtn || editFlg[19] || fetchLoadingDevices[19] || isFetchAll}>
                                <CachedIcon fontSize="medium" style={isDisbleRefeshBtn || editFlg[19] === true || fetchLoadingDevices[19] || isFetchAll ? disabledRefreshAllBtnStyle : enableRefreshAllBtnStyle} />
                            </IconButton>
                        </div>
                    }
                    title='地絡警報 設定 / 子機4'
                    titleTypographyProps={{ variant: 'h5' }}
                />
                <CardContent style={{ padding: '0 0 16px 0' }}>
                    {fetchLoadingDevices[19] || editFlg[19] ? (
                        <div style={{ display: 'flex', position: 'relative' }}>
                            <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT1</td>
                                    </tr>
                                    <tr>
                                        <td style={pollingColStyleTitle}>CT2</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                                <CircularProgress style={{ margin: 'auto' }} />
                            </div>
                        </div>
                    ) : (
                        <table className='polling' style={{ borderCollapse: 'collapse', width: '100%', margin: 'auto' }}>
                            <tbody>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT1</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_4?.ct1_value}</td>
                                </tr>
                                <tr>
                                    <td style={pollingColStyleTitle}>CT2</td>
                                    <td style={pollingColStyle}>{showData?.data_QU_4?.ct2_value}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <Typography display='flex' justifyContent='flex-end' padding='4px 4px 0 0'>
                        最終取得日時：{showData?.data_QU_4?.last_get_datetime ?? '-'}
                    </Typography>
                </CardContent>
            </Card>

            {
                openSelectDialog && 
                <SelectEarthFaultAlarmSettingDialog
                    isOpen={openSelectDialog}
                    onOK={(data:any) => handleUpdateOk(data)}
                    onCancel={handleUpdateCancel}
                    dialogTitle={detailData.title}
                    data={detailData}
                />
            }

            {
                deviceNos.map((deviceNo, deviceIndex) =>
                    codeUpdates.map((code_update, codeIndex) => (
                        <FetchHelper
                            key={`${deviceIndex}-${codeIndex}-`}
                            fetchId={`${UPDATE_EARTH_FAULT_ALARM_SETTING}_device_no=${deviceNo}_code_update=${code_update}`}
                            onComplete={(success, data, error) => 
                                success ? handleUpdateSuccess(data, deviceNo ,code_update) : handleUpdateError(data, deviceNo ,code_update)
                            }
                        />
                    ))
                )
            }

            {
                deviceNos.map((deviceNo, deviceIndex) =>
                    codeGets.map((codeGet, codeIndex) => (
                        <FetchHelper
                            key={`${deviceIndex}-${codeIndex}`}
                            fetchId={`${fetchId}_device_no=${deviceNo}_code_get=${codeGet}`}
                            onComplete={(success, data, error) => 
                                success ? handleSuccess(data, deviceNo ,codeGet) : handleError(data, deviceNo ,codeGet)
                            }
                        />
                    ))
                )
            }
            
        </>
    );
};


const mapStateToProps = (state: ApplicationState) => ({
    fetchState: selectors.getFetchState(
        state,
        fetchId
    ) as FetchState,
});

const mapDispatchToProps = {
    onSearch: (params:any,fetch_id:any) => actionCreators.fetch(fetch_id,`/polling/get?code=${params.code_get}&mainid=${params.mainid}&device_no=${params.device_no}`,"GET",null),
    onUpdate: (params: any,code_update:any) => actionCreators.fetch(`${UPDATE_EARTH_FAULT_ALARM_SETTING}_device_no=${params.device_no}_code_update=${code_update}`, `/polling/set?code=${code_update}`, 'POST', params, false, true),
    onUpdateSuccess: (title:string) =>
        actionCreators.showMessage({
            type: "info",
            title: `${title}`,
            body: `${title}変更新しました。`,
        }),
    onUpdateError: (title:string) =>
        actionCreators.showMessage({
            type: "error",
            title: `${title}`,
            body: `${title}を変更できませんでした。`,
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EarthFaultAlarmSetting);