import BuildIcon from '@mui/icons-material/Build';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Stack } from '@mui/material';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css"; // ← ポイント！
import { AgGridReact } from "ag-grid-react";
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react";
import { PageData } from '../../../models';
import { AG_GRID_LOCALE_JP } from '../../../theme/localeJP';
import { convertTimeToSecond, formatTime } from '../../../utils';
import LoadingOverlay, { DefaultLoadingMessage } from '../../common/LoadingOverlay';
import PaginationCpn from '../../common/PaginationCpn';
import NoRowsOverlay, { NoRowsMessage } from '../../common/NoRowsOverlay';
import { customerLabelName } from '../../../statics';

// -------------
// Props
export type GbGridProps = {
    colState: any[];
    pageSize: number;
    pageData: PageData;
    onChangeSearchCondition: (name: string, value: any) => void;
    onShowDetails: (value: any, operation: string) => void;
    keyword: string;
    openSearch: boolean;
    authRole:any;
};

// -------------
// Component
export const GbGrid = forwardRef((props: GbGridProps, ref) => {
    const { colState, pageSize, pageData, keyword, openSearch, authRole, onChangeSearchCondition, onShowDetails } = props;

    const gridRef = useRef() as any;
    const [rowData, setRowData] = useState();

    useImperativeHandle(ref, () => ({
        saveState() {
            if (gridRef && gridRef.current && gridRef.current.columnApi) {
                return gridRef.current.columnApi.getColumnState();
            }
            return null;
        },

        setRowData(value: any) {
            setRowData(value)
        },

        hideOverlay() {
            gridRef.current.api.hideOverlay();
        }
    }));

    // ag-Grid の準備完了後に呼ばれるコールバック関数
    const onGridReady = (grid: any) => {

    };

    // Loading時のメッセージ
    const loadingOverlayComponent = useMemo(() => {
        return LoadingOverlay;
    }, []);
    const loadingOverlayComponentParams = useMemo(() => {
        return {
            loadingMessage: DefaultLoadingMessage,
        };
    }, []);

    const noRowsOverlayComponent = useMemo(() => {
        return NoRowsOverlay;
    }, []);

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => NoRowsMessage,
        };
    }, []);

    // 編集ボタンのセル内表示とボタンイベント
    const cellMessageRendererTime = (props: any) => {
        // 詳細ボタンイベント
        const handleEdit = () => {
            onShowDetails(props.data, "1");
        };

        if (props.data.restore_datetime != null) {
            return props.data.restore_datetime;
        }
        else if (props.data.restore_datetime === null && props.data.status_name !== "発生") {
            return;
        }

        return (
            <>
                <Stack direction="row">
                    <div>
                        {props.data.restore_datetime}
                    </div>
                    <div style={{ marginInlineStart: 'auto' }}>
                        <IconButton
                            aria-label="edit"
                            onClick={handleEdit}
                        >
                            <BuildIcon fontSize="small" />
                        </IconButton>
                    </div>
                </Stack>
            </>
        );
    };

    const cellMessageRendererComment = (props: any) => {
        // 詳細ボタンイベント
        const handleEdit = () => {
            onShowDetails(props.data, "2");
        };

        return (
            <>
                <Stack direction="row">
                    <div>
                        {props.data.comment}
                    </div>
                    <div style={{ marginInlineStart: 'auto' }}>
                        <IconButton
                            aria-label="edit"
                            onClick={handleEdit}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>
                </Stack>
            </>
        );
    };

    const cellStatusRenderer = (props: any) => {
        gridRef.current?.api?.setQuickFilter(keyword)
        if (props.data.restore_datetime === null && props.data.status_name === "発生") {
            return (
                <>
                    <WarningIcon fontSize="small" style={{ verticalAlign: 'text-top', color: "red" }} /> {props.data.status_name}
                </>
            );
        }
        return (
            <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.data.status_name}
            </>
        );
    };

    // 全列に共通の定義
    const defaultColDef = {
        sortable: true, // ソート可
        editable: false, // 編集不可
        resizable: true,
        suppressMovable: true,  // 列の移動を無効にする
    };

    // 列タイプの定義
    const columnTypes = {
        nonEditableColumn: { editable: false }, // 編集不可
    };

    const setFormatTime = (props: any) => {
        return formatTime(props.data.warning_time);
    };

    // 列の個別定義
    const columnDefs = [
        {
            headerName: "No.",
            field: "id",
            rowDrag: false,
            width: 80,
        },
        {
            headerName: "状況",
            field: "status_name",
            rowDrag: false,
            cellRenderer: cellStatusRenderer,
            width: 100,
        },
        {
            headerName: "受信日時",
            field: "occurrence_time",
            rowDrag: false,
            width: 200,
        },
        {
            headerName: "経過時間",
            field: "elapsed_time",
            rowDrag: false,
            width: 100,
            comparator:(valueA:any,valueB:any) => {
                const a = convertTimeToSecond(valueA);
                const b = convertTimeToSecond(valueB);
                if (a == b) return 0;
                return (a > b) ? 1 : -1;
            }
        },
        {
            headerName: `${customerLabelName}名`,
            field: "customer_name",
            rowDrag: false,
            width: 200,
        },
        {
            headerName: "設置場所",
            field: "place",
            rowDrag: false,
            width: 200,
        },
        {
            headerName: "装置ID",
            field: "mainid",
            rowDrag: false,
            width: 110,
        },
        {
            headerName: "警報発生時間",
            field: "warning_time",
            rowDrag: false,
            valueGetter: setFormatTime,
            width: 120,
        },
        {
            headerName: "目標電力",
            field: "target_kw",
            rowDrag: false,
            width: 200,
        },
        {
            headerName: "現在電力",
            field: "current_kw",
            rowDrag: false,
            width: 200,
        },
        {
            headerName: "予測電力",
            field: "predict_kw",
            rowDrag: false,
            width: 200,
        },
        {
            headerName: "解除区分",
            field: "restore_type",
            width: 100,
        },
        {
            headerName: "解除日時",
            field: "restore_datetime",
            width: 200,
            cellRenderer: cellMessageRendererTime,
        },
        {
            headerName: "確認コメント",
            field: "comment",
            width: 550,
            cellRenderer: cellMessageRendererComment,
        },
    ];

    if(authRole && authRole.base_view_flag == 1){
        columnDefs.splice(4, 0, {
            headerName: "拠点",
            field: "base_name",
            width: 100,
        } as any);
    }

    const rowHeight = 48;
    gridRef.current?.api?.setQuickFilter(keyword)

    return (
        <>
            <div>
                <div className="ag-theme-alpine" style={{ height: openSearch ? 'calc(100vh - 335px)' : 'calc(100vh - 240px)', width: "100%" }}>
                    <AgGridReact
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        columnTypes={columnTypes}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        domLayout={"normal"}
                        rowData={rowData}
                        animateRows={true}
                        rowDragManaged={true}
                        suppressMovableColumns={false}
                        suppressDragLeaveHidesColumns={false}
                        rowHeight={rowHeight}
                        localeText={AG_GRID_LOCALE_JP}
                        paginationPageSize={pageSize}
                        cacheQuickFilter={true}
                        loadingOverlayComponent={loadingOverlayComponent}
                        loadingOverlayComponentParams={loadingOverlayComponentParams}
                        noRowsOverlayComponent={noRowsOverlayComponent}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    />
                </div>
                <PaginationCpn
                    pageData={pageData}
                    onChangeSearchCondition={onChangeSearchCondition}
                    limit={pageSize}
                    exportCsv={{ gridApi: gridRef?.current?.api, name: "デマンド注意警報" }}
                    enabledExportCsv={true}
                />
            </div>
        </>
    );
});

export default GbGrid;
