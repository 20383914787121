import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { Breakpoint, styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AccountPopover from '../components/AccountPopover';
import Logo from '../components/Logo';
import NavSection from '../components/navigation/NavSection';
import useResponsive from '../hooks/useResponsive';
import { Customer, NavMenuItem } from '../models';
import { Path as RoutePath } from "../path";
import { customerLabelName } from '../statics';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    },
}));

// ----------------------------------------------------------------------

type DashboardSidebarProps = {
    isOpenSidebar: boolean,
    onCloseSidebar: Function,
    onOpenMenu: () => void;
    openMenu: boolean;
}

export default function DashboardSidebar(props: DashboardSidebarProps) {
    const version = process.env.REACT_APP_VERSION;
    const { pathname } = useLocation();
    const { isOpenSidebar, onCloseSidebar, onOpenMenu, openMenu } = props;
    let isDesktop = useResponsive({ query: 'up', key: 'lg' as Breakpoint });
    
    const [sidebarConfig, setSidebarConfig] = useState<NavMenuItem[]>([]);

    const restrictionPathList = (key: string) => [
        {
            key: "alarm",
            title: "全般",
            path: RoutePath.AlarmList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key: "abac",
            title: "停電・復電",
            path: RoutePath.AbacList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key: "ad",
            title: "接点警報",
            path: RoutePath.AdList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key: "gb",
            title: "デマンド注意警報",
            path: RoutePath.GbList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key: "gc",
            title: "デマンド限界警報",
            path: RoutePath.GcList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key: "ebec",
            title: "漏電警報",
            path: RoutePath.EbecList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key: "bx",
            title: "地絡警報",
            path: RoutePath.BxList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key: "be",
            title: "微地絡トレンド電流",
            path: RoutePath.BeList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key: "gademand",
            title: "デマンド定期通報",
            path: RoutePath.GaList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key: "gasolar",
            title: "太陽光定期通報",
            path: RoutePath.Ga2List + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        },
        {
            key:"fa",
            title:"測定値定期通報",
            path:RoutePath.FaList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon:"",
        },
        {
            key: "ae",
            title: "死活監視",
            path: RoutePath.AeList + (key ? "?path=customer&customer_id=" + key : "?path=root"),
            icon: "",
        }, 
    ]

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleOpenMenu = () => {
        onOpenMenu();
    };
    
    const handleSetMenusData = (menusData: any) => {
        let tempSidebarConfig:any = [];
        if (menusData.auth_role_map){
            if (menusData.auth_role_map["LM-dashboard-view"]) {
                tempSidebarConfig = [
                    {
                        key:RoutePath.Dashboard,
                        title:"ダッシュボード",
                        path:RoutePath.Dashboard,
                        icon:""
                    }
                ]
            }

            if (menusData.auth_role_map["LM-solar-view"]) {
                tempSidebarConfig = [
                    ...tempSidebarConfig,
                    {
                        key: RoutePath.SolarLiveStatus, 
                        title: "太陽光ライブ", 
                        path: "/solarLiveStatus", 
                        icon: ''
                    }
                ]
            }

            if (menusData.auth_role_map["LM-telemetry-view"]) {
                let childrenPath = restrictionPathList('')
                if (menusData.code_restriction_array?.length > 0) {
                    childrenPath = restrictionPathList('').filter(e => 
                        menusData.code_restriction_array.includes(e.key.toUpperCase()) 
                    )
                }
                tempSidebarConfig = [
                    ...tempSidebarConfig,
                    {
                        key: RoutePath.TelemetryRoot,
                        title: "通報",
                        path: "/telemetry",
                        icon: '',
                        children: childrenPath
                    },
                ]
            }
            
            if(menusData.auth_role_map["LM-smonitor-view"]){
                const child:any[] = [];
                if(menusData.code_restriction_array?.length > 0){
                    let hiddenDemandFlag = true;
                    let hiddenSolarFlag = true;
                    menusData.code_restriction_array.forEach((item:any) => {
                        if(item === "GASOLAR")
                            hiddenSolarFlag = false;
                        else if(item === "GADEMAND")
                            hiddenDemandFlag = false;
                    })
                    if(!hiddenDemandFlag){
                        child.push({
                            key: "/smartdemand",
                            title: "スマートデマンド",
                            path: RoutePath.SmartDemandList,
                            icon: "",
                        },
                        {
                            key: "デマンド警報",
                            title: "デマンド警報",
                            path: RoutePath.AlarmDemandList,
                            icon: "",
                        },
                        {
                            key: "デマンド設定",
                            title: "デマンド設定",
                            path: RoutePath.SettingDemandList,
                            icon: "",
                        })
                    }
                    if(!hiddenSolarFlag){
                        child.push({
                            key: "スマート太陽光",
                            title: "スマート太陽光",
                            path: RoutePath.SmartSolarList,
                            icon: "",
                        },
                        {
                            key: "太陽光設定",
                            title: "太陽光設定",
                            path: RoutePath.SettingSolarList,
                            icon: "",
                        },)
                    }
                }else{
                    child.push(
                        {
                            key: "/smartdemand",
                            title: "スマートデマンド",
                            path: RoutePath.SmartDemandList,
                            icon: "",
                        },
                        {
                            key: "デマンド警報",
                            title: "デマンド警報",
                            path: RoutePath.AlarmDemandList,
                            icon: "",
                        },
                        {
                            key: "デマンド設定",
                            title: "デマンド設定",
                            path: RoutePath.SettingDemandList,
                            icon: "",
                        },
                        {
                            key: "スマート太陽光",
                            title: "スマート太陽光",
                            path: RoutePath.SmartSolarList,
                            icon: "",
                        },
                        {
                            key: "太陽光設定",
                            title: "太陽光設定",
                            path: RoutePath.SettingSolarList,
                            icon: "",
                        },
                    )
                }

                tempSidebarConfig = [
                    ...tempSidebarConfig,
                    {
                        key:"smonitor",
                        title:"スマート監視",
                        path:"/smonitor",
                        icon:"",
                        children:child
                    },
                ]
            }
            else if(menusData.auth_role_map["LM-smonitormobile-view"]){
                const child:any[] = [];
                if(menusData.code_restriction_array?.length > 0){
                    let hiddenDemandFlag = true;
                    let hiddenSolarFlag = true;
                    menusData.code_restriction_array.forEach((item:any) => {
                        if(item === "GASOLAR")
                            hiddenSolarFlag = false;
                        else if(item === "GADEMAND")
                            hiddenDemandFlag = false;
                    })
                    if(!hiddenDemandFlag){
                        child.push({
                            key: "/smartdemand",
                            title: "スマートデマンド",
                            path: RoutePath.SmartDemandList,
                            icon: "",
                        },
                        )
                    }
                    if(!hiddenSolarFlag){
                        child.push({
                            key: "スマート太陽光",
                            title: "スマート太陽光",
                            path: RoutePath.SmartSolarList,
                            icon: "",
                        },
                        )
                    }
                }else{
                    child.push(
                        {
                            key: "/smartdemand",
                            title: "スマートデマンド",
                            path: RoutePath.SmartDemandList,
                            icon: "",
                        },
                        {
                            key: "スマート太陽光",
                            title: "スマート太陽光",
                            path: RoutePath.SmartSolarList,
                            icon: "",
                        },
                    )
                }

                tempSidebarConfig = [
                    ...tempSidebarConfig,
                    {
                        key:"smonitor",
                        title:"スマート監視",
                        path:"/smonitor",
                        icon:"",
                        children:child
                    },
                ]
            }

            const hasAccessToGateway = menusData.auth_role_map["LM-gateway-view"];
            const hasAccessToGatewayJSP = menusData.auth_role_map["LM-gatewayjsp-view"];
            const hasAccessToNotifyList = menusData.auth_role_map["LM-gatewaynotifylist-view"];

            if (hasAccessToGateway || hasAccessToGatewayJSP || hasAccessToNotifyList) {
                const child: any[] = [];
                const hiddenGatewayFlag = !hasAccessToGateway; 
                const hiddenGatewayJSPFlag = !hasAccessToGatewayJSP;
                const hiddenNotifyListFlag = !hasAccessToNotifyList;
                if(!hiddenGatewayFlag){
                    child.push({
                        key: "device",
                        title: "監視装置",
                        path: RoutePath.DeviceList,
                        icon: "",
                    })
                }
                if(!hiddenGatewayJSPFlag){
                    child.push({
                        key: "device_JSP",
                        title: "監視装置-旧版",
                        path: RoutePath.DeviceJSPList,
                        icon: "",
                    })
                }
                
                if(!hiddenNotifyListFlag){
                    child.push({
                        key: "notify_list",
                        title:"通報一覧",
                        path: RoutePath.NotifyList,
                        icon: "",
                    });
                }

                tempSidebarConfig = [
                    ...tempSidebarConfig,
                    {
                        key:"/polling",
                        title:"監視装置設定",
                        path:"/polling",
                        icon:"",
                        children:child
                    },
                ]
            }

            if (menusData.auth_role_map["LM-master-view"]) {
                const temp_array:any = [];
                if(menusData.auth_role_map["LM-mcustomergroup-view"]){
                    temp_array.push({
                        key: "customers-group",
                        title: `組織グループ`,
                        path: RoutePath.CustomersGroupList,
                        icon: "",
                    });
                }
                if(menusData.auth_role_map["LM-mcustomer-view"]){
                    temp_array.push({
                        key: "customers",
                        title: `${customerLabelName}`,
                        path: RoutePath.CustomersList,
                        icon: "",
                    });
                }
                if(menusData.auth_role_map["LM-muser-view"]){
                    temp_array.push({
                        key: "users",
                        title: "ユーザー",
                        path: RoutePath.UsersList,
                        icon: "",
                    });
                }
                if(menusData.auth_role_map["LM-mweather-view"]){
                    temp_array.push({
                        key: "weathers",
                        title: "気象情報",
                        path: RoutePath.Weather,
                        icon: '',
                    });
                }

                tempSidebarConfig = [
                    ...tempSidebarConfig,
                    {
                        key: "master",
                        title: "マスター設定",
                        path: "/master",
                        icon: '',
                        children: temp_array,
                    },
                ]
            }

            if (menusData.auth_role_map["LM-customer-view"]) {
                // create customers path
                let customPath = menusData.customer_info_array.map((e: Customer) => { 
                    // create customer's restriction path
                    let childrenPath
                    if (menusData.auth_role_map["BC-customertelemetryview-belong"]) {
                        if (menusData.code_restriction_array?.length > 0) {
                            childrenPath = restrictionPathList(e.id.toString()).filter(e2 => 
                                menusData.code_restriction_array.includes(e2.key.toUpperCase()) 
                            )
                        } else {
                            childrenPath = restrictionPathList(e.id.toString())
                        }
                    }                    
                    return { 
                        key: '',
                        title: e.name,
                        path: '',
                        icon: '',
                        children: childrenPath,
                    } as NavMenuItem 
                })

                let customerList = {
                    key: '',
                    title: `${customerLabelName}別`,
                    path: '',
                    icon: '',
                    children: customPath,
                } as NavMenuItem 

                // set customers path
                tempSidebarConfig = [
                    ...tempSidebarConfig,
                    customerList
                ]
            }

            setSidebarConfig(tempSidebarConfig);
        }
    };

    const renderContent = (
        <>
            <Box sx={{ px: 2.5, py: 1, display: 'inline-flex' }}>
                <Logo setMenusData={handleSetMenusData}/>
                <Stack justifyContent="center">
                    <Box sx={{ ml: 1 }}>
                        <Typography variant="h6">遠方監視サービス</Typography>
                    </Box>
                </Stack>
            </Box>
            <Box sx={{ px: 2.5, py: 1, display: 'inline-flex' }}>
                <Stack justifyContent="center">
                    <AccountPopover />
                </Stack>
            </Box>

            <div style={{ height: '100%', width: '100%', marginTop: '20px', overflowY: 'auto' }}>
                <div style={{ marginLeft: '20px' }}>
                    <NavSection navConfig={sidebarConfig}></NavSection>
                </div>
            </div>
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ px: 2.5 }}>
                <Stack
                    alignItems="end"
                    sx={{ pt: 3 }}
                >
                    <IconButton onClick={handleOpenMenu}>
                        <KeyboardBackspaceIcon fontSize='large' style={{ color: 'black' }} />
                    </IconButton>
                </Stack>
            </Box>

            <Box sx={{ px: 2.5, pb: 3 }}>
                <Stack
                    alignItems="center"
                    spacing={3}
                    sx={{ borderRadius: 2, position: 'relative' }}
                >
                    <div>{version}</div>
                </Stack>
            </Box>
            {/* </Scrollbar> */}
        </>
    );

    return (
        <>
            {isDesktop && openMenu ? (
                <RootStyle>
                    <Drawer
                        open
                        variant="persistent"
                        PaperProps={{
                            sx: {
                                width: DRAWER_WIDTH,
                                bgcolor: 'background.default',
                                borderRightStyle: 'dashed'
                            }
                        }}
                    >
                        {renderContent}
                    </Drawer>
                </RootStyle>
            ) : (
                <Drawer
                    open={isOpenSidebar}
                    onClose={() => onCloseSidebar()}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH }
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </>
    );
}