import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Autocomplete, Button, Checkbox, CircularProgress, Collapse, Grid, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useSearchParams } from 'react-router-dom';
import { OptionItem, PageData } from "../../../models";
import { customerLabelName, limitMasterCustomers, limitMasterGateways } from '../../../statics';
import { ApplicationState, FetchState, actionCreators, selectors } from "../../../store";
import palette from '../../../theme/palette';
import { formatDateTime } from '../../../utils';
import FetchHelper from "../../FetchHelper";
import CommentDialog from '../../common/CommentDialog';
import Page from "../../common/Page";
import BxGrid from './BxGrid';
// import BXGrid from "./BXGrid";

// -------------
// FETCH ID
const menusFetchId = 'MENUS_FETCH';
const fetchId = "BX_LIST";
const formId = "BX_SEARCH_FORM";
const commentUpdate = "BX_COMMENT_UPDATE";
const customerFetch = "BX_CUSTOMER_FETCH";
const mainidFetch = "BX_MAINID_FETCH";

let statusData: OptionItem[] = [
    {
        value: "1",
        label: "発生",
        attribute: ""
    },
    {
        value: "0",
        label: "復帰",
        attribute: ""
    },
];

const targetCodeData:OptionItem[] = [
    {
        value:"BA",
        label:"微地絡下限検出",
        attribute:""
    },
    {
        value:"BB",
        label:"微地絡警戒検出",
        attribute:""
    },
    {
        value:"BC",
        label:"微地絡警報",
        attribute:""
    },
    {
        value:"BD",
        label:"地絡警報",
        attribute:""
    },    
]

// 検索フォーム
export interface SearchItemForm {
    limit: number;
    page: number;
    target_code:OptionItem[];
    status: OptionItem | null;
    customer_id: OptionItem | null;
    place: string;
    mainid: OptionItem | null;
    occurrence_time_from: Date | null;
    occurrence_time_to: Date | null;
    leak_min: string;
    leak_max: string;
    settingBase:any;
}

// 初期値設定
const initialValue: SearchItemForm = {
    limit: 50,
    page: 0,
    target_code:[],
    status: null,
    customer_id: null,
    place: "",
    mainid: null,
    occurrence_time_from: null,
    occurrence_time_to: null,
    leak_min: '',
    leak_max: '',
    settingBase:[],
}

const initialPageData: PageData = {
    rowFrom: '0',
    rowTo: '0',
    totalRow: '0',
    currPage: '0',
    totalPage: '0',
};

// -------------
// Props
export type BxListProps = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps;

// -------------
// Component
export const BxList = (props: BxListProps) => {
    const {
        onSearch,
        onError,
        onUpdateComment,
        onSuccess,
        onUpdateError,
        onGetAuthRole,
        fetchStateMenu,
    } = props;

    const { handleSubmit, control, setValue, getValues } = useForm<SearchItemForm>({
        defaultValues: initialValue
    });

    const [openSearch, setOpenSearch] = React.useState(false);
    const [pageData, setPageData] = React.useState(initialPageData);
    const [listData, setListData] = React.useState<any>([]);
    const [colState, setColState] = useState<any>();
    const [searchParams] = useSearchParams();
    const [statusList] = React.useState<OptionItem[]>(statusData);
    const [customerList, setCustomerList] = React.useState<any>(null);
    const [mainidList, setMainidList] = React.useState<OptionItem[]>([]);
    const [latestUpdates, setLatestUpdates] = React.useState("");
    const [keyword, setKeyword] = useState("");
    const [openCommentDialog, setOpenCommentDialog] = React.useState(false);
    const [operation, setOperation] = useState("");
    const [dataSelect, setDataSelect] = useState<any>();
    const [loadingFlag, setLoadingFlag] = React.useState(false);
    const [authRole, setAuthRole] = React.useState<any>();
    
    localStorage.setItem('lastActiveFormPath', searchParams.get('path') === 'root' ? window.location.pathname + "?path=root" :  window.location.pathname + window.location.search);
    localStorage.setItem('title', '地絡警報');
    const isRoot = searchParams.get('path') === 'root';
    const isHaveCustomerId = searchParams.get('customer_id');
    const myParam = searchParams.get('id');
    const gridRef = useRef() as any;
    const isShowCustomerSearch = !(searchParams.get('path') === 'customer' && searchParams.get('customer_id'));

    console.log(authRole,"authRole");

    // reload data when change URL query strings
    const watchChangeUrl = searchParams.get('customer_id');

    // 初回検索実行
    React.useEffect(() => {
        setTimeout(() => {
            localStorage.removeItem("currentUrl");
        },2000)
        if(fetchStateMenu && fetchStateMenu.data){
            setAuthRole(fetchStateMenu.data);
        } else {
            onGetAuthRole();
        }
    },[])
    
    React.useEffect(() => {
        if(customerList){
            if(isRoot && isHaveCustomerId){
                const temp_data = customerList.find((item:any) => item.value == searchParams.get("customer_id"));
                if(temp_data){
                    setValue("customer_id",temp_data);
                    setOpenSearch(true);
                    handleSearchSubmit({...getValues(),customer_id:temp_data});
                }else{
                    handleSearchSubmit(getValues());
                }
            }else{
                handleSearchSubmit(getValues());
            }
        }
        // eslint-disable-next-line
    }, [customerList]);

    React.useEffect(() => {
        if (!loadingFlag){
            const params = new Map<string, any>();

            params.set('code', 'BX');
            params.set('limit', getValues('limit'));
            params.set('path', searchParams.get('path') ?? 'root');
            if (!isShowCustomerSearch)
                params.set('customer_id', searchParams.get('customer_id'));
            if (isHaveCustomerId && isRoot)
                params.set('customer_id', searchParams.get('customer_id'));

            handleClearSeachCondition();
            setLoadingFlag(true);
            onSearch(params);
        }        
    }, [watchChangeUrl]);

    // 検索実行
    const handleSearchSubmit = (value: SearchItemForm) => {
        let isError = false;

        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }

        const params = new Map<string, any>();
        params.set("code", "BX")
        params.set('path', searchParams.get('path') ?? 'root');
        if (value.limit) params.set("limit", value.limit);
        if (value.page) params.set("page", value.page - 1);
        if(value.target_code && value.target_code.length > 0){
            let key = "";
            value.target_code.map((item)=>{
                key += item.value;
            })
            params.set("target_code", key);
        }

        if (value.status && value.status.value) params.set("status", value.status.value);
        if (isShowCustomerSearch || isRoot && isHaveCustomerId){
            if (value.customer_id && value.customer_id.value) 
                params.set("customer_id", value.customer_id.value);
        } else {
            params.set('customer_id', searchParams.get('customer_id'));
        }
        if (value.place) params.set("place", value.place);
        if (value.mainid && value.mainid.value) params.set("mainid", value.mainid.value);
        if (value.leak_min) params.set("leak_min", value.leak_min);
        if (value.leak_max) params.set("leak_max", value.leak_max);
        if (value.occurrence_time_from) params.set("occurrence_time_from", formatDateTime(value.occurrence_time_from));
        if (value.occurrence_time_to) params.set("occurrence_time_to", formatDateTime(value.occurrence_time_to));

        const settingBase = [...value.settingBase];
        settingBase.sort((a:any,b:any) => {
            return Number(a.base_id) - Number(b.base_id);
        })
        let base_id = settingBase.map((item) => item.base_id).join("_");
        if(base_id){
            params.set("base_id", base_id);
        }

        if (!isError) {
            setLoadingFlag(true);
            onSearch(params);
        }
    }

    // 検索正常終了時
    const handleSuccess = (data: any) => {
        if (data.results) {
            if (gridRef && gridRef.current) {
                gridRef.current.setRowData(data.results);
            }

            setListData(() => [...data.results]);

            // if (myParam) {
            //     listData.forEach((item: any) => {
            //         item.serial_no = myParam
            //     });
            //     setListData(()=>[...listData])
            // } 
            // else {
            //     listData.forEach((item: any) => {
            //         item.serial_no = Math.floor(Math.random() * 10000).toString().padStart(5, '0')
            //     });
            //     setListData(()=>[...listData])
            // }

            const totalCount = data.total_count;
            const pageSize = getValues('limit');
            const page = Number(data.page) + 1;
            const totalPage = Math.ceil(totalCount / pageSize);
            const rowFrom = (pageSize * page - pageSize) + 1;
            const rowTo = (pageSize * page) > totalCount ? totalCount : (pageSize * page);

            const updateData: PageData = {
                rowFrom: rowFrom.toLocaleString(),
                rowTo: rowTo.toLocaleString(),
                totalRow: totalCount.toLocaleString(),
                currPage: page.toLocaleString(),
                totalPage: totalPage.toLocaleString(),
            };

            setPageData(updateData);
        } else {
            setListData(() => [...[] as any]);
        }
        setLatestUpdates(formatDateTime(new Date()));
        setLoadingFlag(false);
    };

    // 検索正常終了時
    const handleUpdateSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data.result_code === 0) {
            onSuccess();
            handleSearchSubmit(getValues());
        }
        else {
            onUpdateError(data.error_message);
        }
    };

    // 検索エラー時
    const handleError = (success: boolean, data: any, error: any) => {
        onError(success, data, error);
        setLoadingFlag(false);
        gridRef.current.hideOverlay();
    };

    // ページ数変更時にAPI検索する
    const handleChangePageData = (name: any, value: any) => {
        // 値変更
        setValue(name, value);
        if (name === 'limit') {
            setValue("page", 0);
        }
        handleSearchSubmit(getValues());
    };

    const onRefreshGrid = () => {
        handleSearchSubmit(getValues());
    }

    React.useEffect(() => {
        // グラフ表示用データの更新
        // --------------------------------
        if (myParam) {
            listData.forEach((item: any) => {
                item.serial_no = myParam
            });
            setListData(() => [...listData])
        }
        else {
            listData.forEach((item: any) => {
                item.serial_no = Math.floor(Math.random() * 10000).toString().padStart(5, '0')
            });
            setListData(() => [...listData])
        }
        // eslint-disable-next-line
    }, [myParam]);

    // ハンドルオープン
    const handleOpen = (setState: any) => {
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
        setState((prev: boolean) => !prev);
    };

    // 検索条件をクリアします
    const handleClearSeachCondition = () => {
        setValue("status", null);
        setValue("customer_id", null);
        setValue("place", "");
        setValue("mainid", null);
        setValue("occurrence_time_from", null);
        setValue("occurrence_time_to", null);
        setValue("leak_min", '');
        setValue("leak_max", '');
        setValue("target_code",[]);
        setValue("settingBase",[]);
    };

    const handleComment = (value: string) => {
        let object = {} as any;
        object.mainid = dataSelect.mainid;
        object.id = dataSelect.id;
        object.operation = operation;
        if (operation === "1") {
            object.restore_datetime = dataSelect.current_time;
        }
        object.comment = value;
        setLoadingFlag(true);
        onUpdateComment(object);
        setOpenCommentDialog(false);
    }

    const handleOpenComment = (data: any, operation: string) => {
        data.current_time = formatDateTime(new Date());
        setDataSelect(data);
        setOperation(operation);
        setOpenCommentDialog(true);
    }

    const handleCloseComment = () => {
        setOpenCommentDialog(false);
    }

    const handleChangeKeyword = (value: string) => {
        setKeyword(value);
        if (gridRef && gridRef.current) {
            setColState(gridRef.current.saveState());
        }
    }

    const handleFetchCustomerSuccess = (data: any) => {
        if (data?.results) setCustomerList(data?.results.map((x: any) => { return { value: x.id.toString(), label: x.name, attribute: '' } as OptionItem }));
    };

    const handleFetchMainidSuccess = (data: any) => {
        if (data?.results) setMainidList(data?.results.map((x: any) => { return { value: x.id.toString(), label: x.id.toString(), attribute: '' } as OptionItem }));
    };

    const handleSearch = (data: any) => {
        setValue("page", 0)
        handleSearchSubmit(getValues())
    };

    const handleFetchAuthRoleSuccess = (data: any) => {
        console.log('>>> handleSuccess');
        if (data){
            setAuthRole(data);
        }
    }

    return (
        <>
            <FetchHelper fetchId={customerFetch}
                url={`/master/customers/get?limit=${limitMasterCustomers}`}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchCustomerSuccess(data) : handleError(success, data, error)
                }}
            />
            <FetchHelper fetchId={mainidFetch}
                url={`/master/gateways/get?limit=${limitMasterGateways}` + (( searchParams.get('path') && searchParams.get('path') === 'customer' && searchParams.get('customer_id')) ? ('&customer_id='+searchParams.get('customer_id')) : "")}
                method="GET"
                request={true}
                onComplete={(success, data, error) => {
                    success ? handleFetchMainidSuccess(data) : handleError(success, data, error)
                }}
            />
            {openCommentDialog ?
                <CommentDialog isOpen={true} operation={operation} onOK={handleComment} onCancel={handleCloseComment} data={dataSelect} /> :
                <div />
            }
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1200, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}
            <div style={{ height: '100%', width: '100%' }}>
                <form onSubmit={handleSubmit(handleSearchSubmit)}>
                    <Stack spacing={1} mb="10px" style={{ border: openSearch ? "2px solid gray" : '', borderRadius: "15px" }}>
                        <div style={{ cursor: 'pointer', display: 'inline-block', marginLeft: '10px' }} onClick={() => { handleOpen(setOpenSearch); }}>
                            <div style={{ display: 'inline-block' }}>
                                <Typography variant="h6" >検索条件</Typography>
                            </div>
                            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                {
                                    openSearch ? <ArrowDropDownIcon /> : <ArrowRightIcon />
                                }
                            </div>
                        </div>
                        <div style={{ marginLeft: "10px", marginRight: "10px", marginBottom: "10px" }}>
                            <Collapse in={openSearch} timeout="auto" unmountOnExit>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems={"end"}
                                    spacing={1}
                                    direction="row"
                                >
                                    <Grid item minWidth="16rem">
                                        <Controller
                                            control={control}
                                            name="target_code"
                                            render={({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    disableCloseOnSelect
                                                    fullWidth
                                                    multiple
                                                    size="small"
                                                    sx={{backgroundColor:"white"}}
                                                    options={targetCodeData}
                                                    isOptionEqualToValue={(option, value) => {return option.value === value.value}}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(event, values) => {
                                                        field.onChange(values)
                                                    }}
                                                    renderOption={(field, option, { selected }) => (
                                                        <li {...field}>
                                                            <Checkbox
                                                                checked={selected}
                                                            />
                                                            {option.label}
                                                        </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="種別" />
                                                    )}
                                        />
                                        )}
                                    />
                                    </Grid>                       
                                    <Grid item minWidth="12rem">
                                        <Controller
                                            control={control}
                                            name="status"
                                            render={({ field: { value } }) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={value}
                                                    options={statusList}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="状態" size="small" style={{ background: palette.background.paper }} />}
                                                    onChange={(_, data) => setValue("status", data)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {
                                        authRole && authRole.base_view_flag == 1 && 
                                        <Grid item minWidth="16rem">
                                            <Controller
                                                control={control}
                                                name="settingBase"
                                                render={({ field }) => (
                                                    <Autocomplete
                                                        {...field}
                                                        disableCloseOnSelect
                                                        fullWidth
                                                        multiple
                                                        defaultValue={undefined}
                                                        size="small"
                                                        options={authRole.base_id_array}
                                                        isOptionEqualToValue={(option, value) => {return option.base_id === value.base_id}}
                                                        getOptionLabel={(option) => option.base_name}
                                                        onChange={(event, values) => {
                                                            field.onChange(values)
                                                        }}
                                                        renderOption={(field, option, { selected }) => (
                                                            <li {...field}>
                                                                <Checkbox
                                                                    checked={selected}
                                                                />
                                                                {option.base_name}
                                                            </li>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField sx={{backgroundColor:"white"}} {...params} label="拠点"/>
                                                        )}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    {isShowCustomerSearch &&
                                        <Grid item minWidth="16rem">
                                            <Controller
                                                control={control}
                                                name="customer_id"
                                                render={({ field: { value } }) => (
                                                    <Autocomplete
                                                        autoHighlight
                                                        value={value}
                                                        options={customerList}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} label={`${customerLabelName}名`} size="small" style={{ background: palette.background.paper }} />}
                                                        onChange={(_, data) => setValue("customer_id", data)}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    <Grid item minWidth="16rem">
                                        <Controller
                                            name="place"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type="text"
                                                    label="設置場所"
                                                    size="small"
                                                    style={{ background: palette.background.paper }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item minWidth="12rem">
                                        <Controller
                                            control={control}
                                            name="mainid"
                                            render={({ field: { value } }) => (
                                                <Autocomplete
                                                    autoHighlight
                                                    value={value}
                                                    options={mainidList}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} label="装置ID" size="small" style={{ background: palette.background.paper }} />}
                                                    onChange={(_, data) => setValue("mainid", data)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems={"end"}
                                    spacing={1}
                                    direction="row"
                                    mt="3px"
                                >
                                    <Grid item xs={4} minWidth="29.5rem">
                                        <Stack spacing={1} direction="row">
                                            <Controller
                                                name="leak_min"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        label="電流-最小値(mA)"
                                                        size="small"
                                                        style={{ background: palette.background.paper }}
                                                        onChange={(e) => {
                                                            if (!isNaN(Number(e.target.value))) {
                                                                field.onChange(e);
                                                            } else {
                                                                field.onChange('');
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            e.target.value && setValue('leak_min', e.target.value.padStart(4, '0'));
                                                        }}
                                                        inputProps={{
                                                            maxLength: 4
                                                        }}
                                                    />
                                                )}
                                            />
                                            <Stack justifyContent="center" alignItems="center">
                                                <div>～</div>
                                            </Stack>
                                            <Controller
                                                name="leak_max"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        label="電流-最大値(mA)"
                                                        size="small"
                                                        style={{ background: palette.background.paper }}
                                                        onChange={(e) => {
                                                            if (!isNaN(Number(e.target.value))) {
                                                                field.onChange(e);
                                                            } else {
                                                                field.onChange('');
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            e.target.value && setValue('leak_max', e.target.value.padStart(4, '0'));
                                                        }}
                                                        inputProps={{
                                                            maxLength: 4
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4} minWidth="29.5rem">
                                        <Stack spacing={1} direction="row">
                                            <Controller
                                                control={control}
                                                name="occurrence_time_from"
                                                render={({ field: { value } }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            inputFormat="YYYY/MM/DD HH:mm:ss"
                                                            renderInput={(props: any) => (
                                                                <TextField
                                                                    size="small"
                                                                    {...props}
                                                                    style={{ background: palette.background.paper }}
                                                                />
                                                            )}
                                                            label="受信日時From"
                                                            value={value}
                                                            onChange={(newValue: any) => {
                                                                setValue("occurrence_time_from", newValue ? newValue.$d : null);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                            <Stack justifyContent="center" alignItems="center">
                                                <div>～</div>
                                            </Stack>
                                            <Controller
                                                control={control}
                                                name="occurrence_time_to"
                                                render={({ field: { value } }) => (
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DateTimePicker
                                                            inputFormat="YYYY/MM/DD HH:mm:ss"
                                                            renderInput={(props: any) => (
                                                                <TextField
                                                                    size="small"
                                                                    {...props}
                                                                    style={{ background: palette.background.paper }}
                                                                />
                                                            )}
                                                            label="受信日時To"
                                                            value={value}
                                                            onChange={(newValue: any) => {
                                                                setValue("occurrence_time_to", newValue ? newValue.$d : null);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined" style={{ background: palette.background.paper }} onClick={handleClearSeachCondition}>クリア</Button>
                                    </Grid>
                                    <Grid item >
                                        <Button variant="contained" onClick={handleSearch}>
                                            検索
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </div>
                    </Stack>
                </form>
                <div style={{ marginBottom: "10px" }}>
                    <Stack direction="row">
                        <Typography variant="body2" style={{ textAlign: "center", lineHeight: "40px" }}>
                            最終表示日時&emsp;{latestUpdates}
                        </Typography>
                        <div style={{ marginInlineStart: 'auto' }}>
                            <Grid container justifyContent="flex-start" alignItems={"center"} direction="row" spacing={1} >
                                <Grid item>
                                    <Page onRefreshGrid={onRefreshGrid}></Page>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        autoComplete="searchStr"
                                        name="keyword"
                                        type="text"
                                        label="フィルター"
                                        size="small"
                                        value={keyword}
                                        onChange={e => handleChangeKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" onClick={e => handleChangeKeyword("")}>クリア</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Stack>
                </div>
                <div>
                    <BxGrid
                        ref={gridRef}
                        colState={colState}
                        pageSize={getValues('limit')}
                        pageData={pageData}
                        onChangeSearchCondition={handleChangePageData}
                        onShowDetails={handleOpenComment}
                        keyword={keyword}
                        openSearch={openSearch}
                        authRole={authRole}
                    />
                </div>
            </div>
            <FetchHelper fetchId={fetchId} onComplete={(success, data, error) =>
                success ? handleSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={commentUpdate} onComplete={(success, data, error) =>
                success ? handleUpdateSuccess(data) : handleError(success, data, error)} />
            <FetchHelper fetchId={menusFetchId} onComplete={(success, data, error) => 
                success ? handleFetchAuthRoleSuccess(data) : handleError(success, data, error)} />
        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    fetchStateMenu: selectors.getFetchState(
        state,
        menusFetchId
    ) as FetchState,
    formState: selectors.getFormState(state, formId),
});

const mapDispatchToProps = {
    onGetAuthRole: () => actionCreators.fetch(menusFetchId, `/master/menus/get`, 'GET', null),
    onSearch: (params: { [key: string]: any }) => actionCreators.fetch(fetchId, `/telemetry/get`, "GET", params),
    onUpdateComment: (data: any) => actionCreators.fetch(commentUpdate, `/telemetry/set?code=BX`, "POST", data, false, true),
    onError: (success: boolean, data: any, error: any) =>
        actionCreators.showMessage({
            type: "error",
            title: "地絡警報検索",
            body: "地絡警報検索を検索できません。" + error,
        }),
    onSuccess: () =>
        actionCreators.showMessage({
            type: "info",
            title: "地絡警報検索",
            body: "情報を更新しました。",
        }),
    onUpdateError: (message: string) =>
        actionCreators.showMessage({
            type: "error",
            title: "地絡警報検索",
            body: message,
        }),
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BxList as any);
